// 引入组件库的少量全局样式变量
import 'tdesign-vue-next/es/style/index.css';

import './assets/main.less';

import 'virtual:uno.css';

import { createPinia } from 'pinia';
import { DataLoaderPlugin } from 'unplugin-vue-router/data-loaders';
import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';

async function init() {
  if (import.meta.env.MODE === 'development' || 1 + 0 === 1) {
    await import('eruda').then(({ default: eruda }) => {
      eruda.init({
        defaults: {
          transparency: 0.9,
        },
      });
      // eruda.show();
    });
  }

  const app = createApp(App)
    .use(createPinia())
    // Register the plugin before the router
    .use(DataLoaderPlugin, { router })
    // adding the router will trigger the initial navigation
    .use(router);
  app.config.globalProperties.$__DEV__ = $__DEV__;
  app.mount('#app');
}

init();

if ($__DEV__) {
  (async () => {
    Object.assign(window, {
      dayjs: (await import('dayjs')).default,
    });
  })();
}
