import { createLogGuard, createProgressGuard, createStackGuard } from 'utils4u/vue-router';
import { type Router, createRouter, createWebHistory } from 'vue-router';
import { handleHotUpdate, routes } from 'vue-router/auto-routes';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  strict: true,
});

if ($__DEV__) Object.assign(window, { router });

setupRouterGuard(router);

// Don't change the order of creation
function setupRouterGuard(router: Router) {
  createProgressGuard(router);
  createLogGuard(router);
  Object.assign(window, { stack: createStackGuard(router) });

  router.onError((error) => {
    console.debug('🚨 [router error]: ', error);
  });
}

if (import.meta.hot) {
  handleHotUpdate(router);
}
