import _definePage_default_0 from '/Users/yanhao2023/workspace/trader-2409/src/pages/index-page.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/index-page',
    name: 'IndexPage',
    component: () => import('/Users/yanhao2023/workspace/trader-2409/src/pages/index-page.vue'),
    /* no children */
  },
  _definePage_default_0
  )
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

