<script lang="tsx" setup>
definePage({ alias: '/' });

import type { CandleChartInterval_LT, OrderSide_LT, QueryFuturesOrderResult } from 'binance-api-node';
import Binance from 'binance-api-node';
import dayjs from 'dayjs';
import { type Chart, type KLineData, LineType, PolygonType, dispose, init, registerStyles } from 'klinecharts';
import { chooseFile } from 'utils4u/browser';
import { onMounted, onUnmounted } from 'vue';

let chart: Chart | null = null;
const red = '#F92855';
const green = '#2DC08E';
const alphaRed = 'rgba(249, 40, 85, .7)';
const alphaGreen = 'rgba(45, 192, 142, .7)';

onMounted(async () => {
  chart = init('chart', {
    locale: 'zh-CN',
    styles: {},
  });

  registerStyles('red_rise_green_fall', {
    candle: {
      bar: {
        upColor: red,
        downColor: green,
        upBorderColor: red,
        downBorderColor: green,
        upWickColor: red,
        downWickColor: green,
      },
      priceMark: {
        last: {
          upColor: red,
          downColor: green,
        },
      },
    },
    indicator: {
      ohlc: {
        upColor: alphaRed,
        downColor: alphaGreen,
      },
      bars: [
        {
          style: PolygonType.Fill,
          borderStyle: LineType.Solid,
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888',
        },
      ],
      circles: [
        {
          style: PolygonType.Fill,
          borderStyle: LineType.Solid,
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888',
        },
      ],
    },
  });
  chart?.setStyles('red_rise_green_fall');

  if ($__DEV__) onSubmit();
});

const symbolList = [
  'BNBUSDT',
  'RUNEUSDT',
  'OPUSDT',
  'FILUSDT',
  'DOTUSDT',
  'ADAUSDT',
  'LINKUSDT',
  'NEARUSDT',
  'BCHUSDT',
  'AAVEUSDT',
  'AVAXUSDT',
  'FTMUSDT',
  'AXSUSDT',
  'NEOUSDT',
  'ETCUSDT',
  'SOLUSDT',
  'ETHUSDT',
  'BTCUSDT',
];

const formData = reactive({
  symbol: symbolList[0],
  interval: '8h' as CandleChartInterval_LT,
  price: '',
  apiKey: localStorage.getItem('apiKey') || '',
  apiSecret: localStorage.getItem('apiSecret') || '',
  startTime: dayjs().subtract(7, 'day').valueOf(),
  endTime: dayjs().valueOf(),
});

type OrderData = {
  time: number;
  side: OrderSide_LT;
  avgPrice: string;
  origQty: string;
  orderId: string;
};

const futuresAllOrders = ref<OrderData[]>([]);

const onSubmit = async () => {
  const apiKey = formData.apiKey;
  const apiSecret = formData.apiSecret;
  localStorage.setItem('apiKey', apiKey);
  localStorage.setItem('apiSecret', apiSecret);
  const binance = Binance({ apiKey, apiSecret });

  await binance
    .futuresAllOrders({
      symbol: formData.symbol,
      startTime: formData.startTime,
      endTime: formData.endTime,
    })
    .then((res) => {
      futuresAllOrders.value = res as unknown as QueryFuturesOrderResult[];
      // console.log('futuresAllOrders.value :>> ', JSON.stringify(futuresAllOrders.value[0], null, 2));
    });

  const dataList: KLineData[] = (
    await binance.candles({
      symbol: formData.symbol,
      interval: formData.interval,
      startTime: formData.startTime,
      endTime: formData.endTime,
      limit: 1000,
    })
  ).map((candle) => {
    return {
      close: Number.parseFloat(candle.close),
      high: Number.parseFloat(candle.high),
      low: Number.parseFloat(candle.low),
      open: Number.parseFloat(candle.open),
      timestamp: candle.closeTime,
      volume: Number.parseFloat(candle.volume),
    };
  });
  chart?.applyNewData(dataList);
  await new Promise((resolve) => setTimeout(resolve, 300));
};
const 隐藏多余字段 = $ref(!!formData.apiKey);
onUnmounted(() => {
  dispose('chart');
});

const onClear = () => {
  chart?.clearData();
  chart?.removeOverlay();
  futuresAllOrders.value = [];
};

watch(futuresAllOrders, (newVal) => {
  if (!newVal.length) return;
  const createOverlayRes = chart?.createOverlay(
    newVal.map((order) => {
      return {
        name: 'simpleAnnotation',
        extendData: `${order.side === 'BUY' ? '📈Buy' : '📉Sell'}: ${order.avgPrice}, ${order.origQty}`,
        points: [
          {
            timestamp: order.time,
            value: Number.parseFloat(order.avgPrice),
          },
        ],
      };
    }),
  );
  console.log('createOverlayRes :>> ', createOverlayRes);
});

import Papa from 'papaparse';
import { DialogPlugin, Message, MessagePlugin } from 'tdesign-vue-next';
const onCsv = async () => {
  const [file] = await chooseFile({ accept: '.csv', multiple: false });
  if (!file) return;
  // origQty

  const csvString = await file.text();
  const res = Papa.parse<Array<string | ''>>(csvString);
  const lines = res.data.filter((line) => line.some((item) => item));
  // 如果 lines[0] 有 side 或 orderId 说明是订单数据
  const orderCheckFields = ['side', 'orderId'];
  let message = '';

  if (orderCheckFields.some((field) => lines[0].some((item) => item.toLowerCase().includes(field)))) {
    message = `将文件 ${file.name} 作为订单数据导入`;
    importOrderData(lines);
  } else {
    importChartData(lines);
    message = `将文件 ${file.name} 作为K线数据导入`;
  }

  MessagePlugin.info({
    content: message,
    closeBtn: true,
    duration: 5000,
    placement: 'top-right',
  });

  function importOrderData(lines: Array<Array<string>>) {
    const orderIdIndex = lines[0].findIndex(
      (item) => item.toLowerCase().includes('order') && item.toLowerCase().includes('id'),
    );
    const timeIndex = lines[0].findIndex(
      (item, index) => item.toLowerCase().includes('time') && dayjs(lines[1][index]).isValid(),
    );
    const sideIndex = lines[0].findIndex((item) => item.toLowerCase().includes('side'));
    const priceIndex = (() => {
      for (const field of ['avgprice', 'price']) {
        const index = lines[0].findIndex(
          (item, index) => item.toLowerCase().includes(field) && !Number.isNaN(Number.parseFloat(lines[1][index])),
        );
        if (index !== -1) return index;
      }
      return -1;
    })();
    const quantityIndex = lines[0].findIndex((item) =>
      ['origqty', 'quantity'].some((field) => item.toLowerCase().includes(field)),
    );

    const dialog = DialogPlugin.alert({
      header: message,
      body: () => (
        <div>
          <t-list-item>字段对应：</t-list-item>
          <t-list-item>
            <t-tag>orderId:</t-tag>
            <t-tag>{lines[0][orderIdIndex]}</t-tag>
          </t-list-item>
          <t-list-item>
            <t-tag>time:</t-tag>
            <t-tag>{lines[0][timeIndex]}</t-tag>
          </t-list-item>
          <t-list-item>
            <t-tag>side:</t-tag>
            <t-tag>{lines[0][sideIndex]}</t-tag>
          </t-list-item>
          <t-list-item>
            <t-tag>avgPrice:</t-tag>
            <t-tag>{lines[0][priceIndex]}</t-tag>
          </t-list-item>
          <t-list-item>
            <t-tag>origQty:</t-tag>
            <t-tag>{lines[0][quantityIndex]}</t-tag>
          </t-list-item>
        </div>
      ),
      onConfirm: () => dialog.destroy(),
    });
    const dataList: OrderData[] = lines.slice(1).map((line) => {
      return {
        time: dayjs(
          !Number.isNaN(Number.parseFloat(line[timeIndex])) ? Number.parseFloat(line[timeIndex]) : line[timeIndex],
        ).valueOf(),
        side: line[sideIndex] as OrderSide_LT,
        avgPrice: line[priceIndex],
        origQty: line[quantityIndex],
        orderId: line[orderIdIndex],
        _raw: line,
      };
    });
    futuresAllOrders.value = dataList;
  }

  function importChartData(lines: Array<Array<string>>) {
    const closeIndex = lines[0].findIndex((item) => item.toLowerCase().includes('close'));
    const highIndex = lines[0].findIndex((item) => item.toLowerCase().includes('high'));
    const lowIndex = lines[0].findIndex((item) => item.toLowerCase().includes('low'));
    const openIndex = lines[0].findIndex((item) => item.toLowerCase().includes('open'));
    const volumeIndex = lines[0].findIndex((item) => item.toLowerCase().includes('volume'));
    const timeIndex = lines[0].findIndex((item) => item.toLowerCase().includes('time'));
    const dataList: KLineData[] = lines.slice(1).map((line) => {
      return {
        close: Number.parseFloat(line[closeIndex] || '0'),
        high: Number.parseFloat(line[highIndex] || '0'),
        low: Number.parseFloat(line[lowIndex] || '0'),
        open: Number.parseFloat(line[openIndex] || '0'),
        timestamp: dayjs(line[timeIndex]).valueOf(),
        volume: Number.parseFloat(line[volumeIndex] || '0'),
      };
    });
    chart?.applyNewData(dataList);
  }
};

const _onSave = async () => {
  // 把订单数据保存到本地
  const csv = Papa.unparse(futuresAllOrders.value.map((order) => order));
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'orders.csv';
  a.click();
  URL.revokeObjectURL(url);
  // 把K线数据保存到本地
  const dataList = chart?.getDataList();
  if (!dataList) return;
  const csvData = dataList.map((data) => {
    return {
      close: data.close,
      high: data.high,
      low: data.low,
      open: data.open,
      timestamp: dayjs(data.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      volume: data.volume,
    };
  });
  const csvString = Papa.unparse(csvData);
  const blobData = new Blob([csvString], { type: 'text/csv' });
  const urlData = URL.createObjectURL(blobData);
  const aData = document.createElement('a');
  aData.href = urlData;
  aData.download = 'kline.csv';
  aData.click();
  URL.revokeObjectURL(urlData);
};
</script>

<template>
  <index-layout>
    <template #left>
      <div id="chart" h-full w-full />
    </template>
    <template #right-top>
      <t-form layout="inline" @submit="onSubmit">
        <t-form-item label="apiKey" v-show="!隐藏多余字段">
          <t-input v-model="formData.apiKey" />
        </t-form-item>
        <t-form-item label="apiSecret" v-show="!隐藏多余字段">
          <t-input v-model="formData.apiSecret" />
        </t-form-item>
        <t-form-item label="symbol">
          <t-select v-model="formData.symbol">
            <template v-for="symbol in symbolList" :key="symbol">
              <t-option :value="symbol">{{ symbol }}</t-option>
            </template>
          </t-select>
        </t-form-item>
        <t-form-item label="interval">
          <!-- <t-input placeholder="1/3/5/15m, 1/2/4/6/8/12h, 1/3d, 1w, 1M" v-model="formData.interval" /> -->
          <t-select v-model="formData.interval">
            <t-option value="1m">1m</t-option>
            <t-option value="3m">3m</t-option>
            <t-option value="5m">5m</t-option>
            <t-option value="15m">15m</t-option>
            <t-option value="30m">30m</t-option>
            <t-option value="1h">1h</t-option>
            <t-option value="2h">2h</t-option>
            <t-option value="4h">4h</t-option>
            <t-option value="6h">6h</t-option>
            <t-option value="8h">8h</t-option>
            <t-option value="12h">12h</t-option>
            <t-option value="1d">1d</t-option>
            <t-option value="3d">3d</t-option>
            <t-option value="1w">1w</t-option>
            <t-option value="1M">1M</t-option>
          </t-select>
        </t-form-item>
        <t-form-item label="time">
          <t-date-range-picker :value="[formData.startTime, formData.endTime]" mode="date" value-type="time-stamp"
            allow-input @change="([startTime, endTime]) => {
              formData.startTime = Number(startTime);
              formData.endTime = Number(endTime);
            }" />
        </t-form-item>

        <t-form-item>
          <t-space size="small" break-line>
            <t-button theme="primary" type="submit">查</t-button>
            <t-button theme="primary" @click="隐藏多余字段 = !隐藏多余字段">
              {{ 隐藏多余字段 ? '显' : '藏' }}
            </t-button>
            <t-button theme="primary" @click="onClear">
              clear
            </t-button>
            <t-button theme="primary" @click="onCsv">
              .csv
            </t-button>

            <t-button theme="primary" @click="_onSave" v-if="$__DEV__"> _save </t-button>

          </t-space>
        </t-form-item>
      </t-form>
    </template>
    <template #right-bottom>
      <t-tabs :default-value="1">
        <t-tab-panel :value="1" label="futuresAllOrders">
          <t-list :split="true">
            <template v-for="order in futuresAllOrders" :key="order.orderId">
              <t-popup show-arrow placement="left" :overlay-style="{ maxHeight: '80vh', overflow: 'auto' }">
                <template #content>
                  <pre>{{ JSON.stringify(order, null, 2) }}</pre>
                </template>
                <t-list-item @click="chart?.scrollToTimestamp(order.time, 280)" cursor-pointer>
                  <t-list-item-meta>
                    <template #title>
                      time: {{ dayjs(order.time).format('YYYY-MM-DD HH:mm:ss') }}
                      id: {{ order.orderId }}
                    </template>
                    <template #description>
                      <t-space break-line>
                        <t-tag text="white!" :style="{ background: order.side === 'BUY' ? green : red }">
                          {{ order.side === 'BUY' ? '📈 Buy' : '📉 Sell' }}
                        </t-tag>
                        <t-tag>avgPrice: {{ order.avgPrice }}</t-tag>
                        <!-- <t-tag>avgPrice: {{ order.avgPrice }}</t-tag> -->
                        <t-tag>origQty: {{ order.origQty }}</t-tag>
                        <!-- <t-tag>time: {{ dayjs(order.time).format('YYYY-MM-DD HH:mm:ss') }}</t-tag> -->
                      </t-space>
                    </template>
                  </t-list-item-meta>
                </t-list-item>
              </t-popup>
            </template>
          </t-list>
        </t-tab-panel>
        <t-tab-panel :value="2" label="Tab 2">
          <div>TODO: 问题1: 单次最大1000根 </div>
          <div>TODO: 问题2: 还原的交易点在K线上没有那个时间点 </div>
          <div>TODO: 问题3: 交易单次最大查7天 </div>
        </t-tab-panel>
      </t-tabs>
    </template>
  </index-layout>
</template>
